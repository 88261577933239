"use client"

import { IconAlertCircle, IconCheck, IconInfoCircle, IconX } from "@tabler/icons-react"
import { useTheme } from "next-themes"
import { ReactNode, useRef } from "react"
import { ExternalToast, toast as sonnerToast, Toaster as Sonner } from "sonner"

import { cn } from "@/utils/tailwind"

type ToasterProps = React.ComponentProps<typeof Sonner>

export function Toaster({ ...props }: ToasterProps) {
  const { theme = "system" } = useTheme()

  return (
    <Sonner
      expand
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast: cn(
            "toast group group-[.toaster]:border-slate-200 group-[.toaster]:bg-white group-[.toaster]:text-slate-950 group-[.toaster]:shadow-lg dark:group-[.toaster]:border-slate-800 dark:group-[.toaster]:bg-slate-950 dark:group-[.toaster]:text-slate-50",
          ),
          description: cn("group-[.toast]:text-slate-500 dark:group-[.toast]:text-slate-400"),
          actionButton: cn(
            "group-[.toast]:bg-slate-900 group-[.toast]:text-slate-50 dark:group-[.toast]:bg-slate-50 dark:group-[.toast]:text-slate-900",
          ),
          cancelButton: cn(
            "group-[.toast]:bg-slate-100 group-[.toast]:text-slate-500 dark:group-[.toast]:bg-slate-800 dark:group-[.toast]:text-slate-400",
          ),
          closeButton: cn("left-auto right-1 top-1 transform-none rounded border-none bg-white"),
        },
      }}
      {...props}
    />
  )
}

export const toast = {
  loading: (...params: Parameters<typeof sonnerToast.loading>) => {
    const [message, opts, ...rest] = params
    return sonnerToast.loading(message, { ...opts }, ...rest)
  },
  info: (...params: Parameters<typeof sonnerToast.info>) => {
    const [message, opts, ...rest] = params
    return sonnerToast.info(
      message,
      {
        icon: <IconInfoCircle className="h-4 w-4 text-slate-600" />,
        ...opts,
      },
      ...rest,
    )
  },
  success: (...params: Parameters<typeof sonnerToast.success>) => {
    const [message, opts, ...rest] = params
    return sonnerToast.success(
      message,
      {
        icon: <IconCheck className="h-4 w-4 text-slate-600" />,
        ...opts,
      },
      ...rest,
    )
  },
  warning: (...params: Parameters<typeof sonnerToast.warning>) => {
    const [message, opts, ...rest] = params
    return sonnerToast.warning(
      message,
      {
        icon: <IconAlertCircle className="h-4 w-4 text-slate-600" />,
        ...opts,
      },
      ...rest,
    )
  },
  error: (...params: Parameters<typeof sonnerToast.error>) => {
    const [message, opts, ...rest] = params
    return sonnerToast.error(
      message,
      {
        icon: <IconX className="h-4 w-4 text-slate-600" />,
        ...opts,
      },
      ...rest,
    )
  },
}

export function useToastFactory() {
  const toastIdRef = useRef<string | number>(undefined)

  return {
    onStart(params?: ExternalToast & { title?: ReactNode }) {
      const { title: message, ...opts } = params ?? {}

      const toastId = toast.loading(message ?? "Ukládám", {
        id: toastIdRef.current,
        description: "Ukládáme data na server, prosím počkejte...",
        closeButton: false,
        ...opts,
      })

      toastIdRef.current = toastId
      return toastId
    },
    onSuccess(params?: ExternalToast & { title?: ReactNode }) {
      const { title: message, ...opts } = params ?? {}

      const toastId = toastIdRef.current
      toastIdRef.current = undefined

      return toast.success(message ?? "Hotovo!", {
        description: "Data byla úspěšně uložena.",
        id: toastId,
        duration: 3500,
        ...opts,
      })
    },
    onError(params?: ExternalToast & { title?: ReactNode }) {
      const { title: message, ...opts } = params ?? {}

      const toastId = toastIdRef.current
      toastIdRef.current = undefined

      return toast.error(message ?? "Chyba!", {
        description: "Něco se pokazilo, zkuste to prosím znovu později.",
        id: toastId,
        duration: 3500,
        ...opts,
      })
    },
  }
}
